<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center p-6 justify-center">
            <div class="mx-2 text-secondary ng-tns-c175-5">
                <!-- Light version -->
                <img
                    class="dark:hidden ng-tns-c175-5 logo"
                    src="assets/images/logo/logo-text-on-dark.png"
                    alt="Logo image">
                <!-- Dark version -->
                <img
                    class="hidden dark:flex ng-tns-c175-5 logo"
                    src="assets/images/logo/logo-text-on-dark.png"
                    alt="Logo image">
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts> -->
            <!-- <messages></messages> -->
            @if(!isVisitant()) {
                <notifications></notifications>
            }
            <!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative overflow-hidden md:flex items-center justify-center w-full h-35 px-4 py-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <img src="../../../../../assets/images/logo/logotipo-kitdigital.png" class="md:h-16 md:w-160 px-5 mb-5 md:mb-0">
        <span class="font-medium text-secondary flex items-center justify-center">{{ 'general.footer.copyright' | transloco }}</span>
    </div>

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
