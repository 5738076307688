import { environment } from "../../../environments/environment";

export class UriUtils {

    static  buildUrl(url: string, ...segments: string[]): string {
        return UriUtils.appendParts(environment.serverUrl, url, ...segments);
    }

    static appendParts(host: string, ...parts: string[]) {
        let builtUrl = host;

        if (builtUrl.endsWith('/')) {
            builtUrl = builtUrl.slice(0, -1);
        }

        parts.forEach((part) => {
            if (!part.startsWith('/')) {
                part = '/' + part;
            }

            builtUrl += part;
        });

        return builtUrl;
    }

    static deleteQueryParam(queryParam: string): void {
        let currentUrl = window.location.href;
        let url = new URL(currentUrl);
        let params = new URLSearchParams(url.search);
        params.delete(queryParam);
        let newUrl = url.origin + url.pathname + '?' + params.toString();
        window.history.replaceState({}, document.title, newUrl);
    }
}
