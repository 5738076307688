import { AfterViewInit, Component, inject } from '@angular/core';
import { UserService } from './core/user/user.service';
import { NotificationService } from './layout/common/notifications/services/notification.service';
import { combineLatest, interval, startWith } from 'rxjs';
import { Role } from './core/constants/roles.enum';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    private _userService = inject(UserService);
    private _notificationService = inject(NotificationService);

    ngAfterViewInit(): void {
        setTimeout(() => {
            combineLatest([
                interval(60000 * 5).pipe(startWith(0)), // Every 5 minutes
                this._userService.user$,
            ]).subscribe(([interval, user]) => {
                if (user.role !== Role.VISITOR) {
                    this._notificationService.searchNotifications();
                }
            })
        }, 5000)
    }
}
