import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Notification } from '../model/notification.model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { NotificationItem } from '../interface/notification-item.types';
import { UriUtils } from '../../../../shared/utils/uri.util';
import { NotificationType } from '../enum/notification.enum';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private _notifications = new BehaviorSubject<NotificationItem[]>([]);
    private url = 'v1/notification';

    constructor(private _httpClient: HttpClient) {}

    searchNotifications(): void {
        if (this._notifications.value.length === 0) {
            this.getNotifications().subscribe((newNotifications) => {
                this._notifications.next(newNotifications);
            });
        }
    }

    getNotifications(): Observable<NotificationItem[]> {
        return this._httpClient.get<Notification[]>(UriUtils.buildUrl(this.url)).pipe(
            map((notifications) => {
                return this.transform(notifications);
            })
        );
    }

    get notifications$(): Observable<NotificationItem[]> {
        return this._notifications.asObservable();
    }

    update(id: number, updateItem: NotificationItem) {
        this.notifications$.pipe(
            map((items: NotificationItem[]) => {
                const updatedItems = items.map((item: NotificationItem) => {
                    if( item.id === id ) {
                        return updateItem;
                    }

                    return item;
                });
                this._notifications.next(updatedItems);
            })
        );
    }

    markAsRead(id: number, read: boolean): Observable<NotificationItem[]> {
        return this._httpClient.patch<Notification[]>(UriUtils.buildUrl(this.url, id.toString()), { read })
            .pipe(tap(() => {
                const updatedItems = this._notifications.value.map((item) => {
                    return item.id === id ? {...item, read: read } : item;
                });
                this._notifications.next(updatedItems);
            }))
    }

    delete(id: number) {
        return this._httpClient.delete<void>(UriUtils.buildUrl(this.url, id.toString())).pipe(
            tap(() => {
                const newNotifications = this._notifications.value.filter(x => x.id !== id);
                this._notifications.next(newNotifications);
            })
        )
    }

    private transform(notifications: Notification[]): NotificationItem[] {
        return notifications.map((notification) => {
            return {
                id: notification.id,
                title: notification.title,
                description: notification.description,
                time: notification.time,
                read: notification.read,
                link: this.getLinkByType(notification.type, notification),
                icon: this.getIcon(notification.type),
                useRouter: notification.useRouter
            };
        });
    }

    private getLinkByType(type: string, notification: Notification) {
        switch (type) {
            // case NotificationType.PAYMENT:
            //     return `/event/${ViewMode.READ}/${notification.parentId}/${EventPanel.PAYMENT}`;
            case NotificationType.REQUESTED_EMAIL:
                return notification.link;
            default:
                return '';
        }
    }

    private getIcon(type: string) {
        switch (type) {
            // case NotificationType.PAYMENT:
            //     return 'heroicons_solid:currency-euro';
            default:
                return 'heroicons_mini:inbox-arrow-down';
        }
    }
}
