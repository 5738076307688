<div class="modal-container">
    <div class="flex justify-between text-center mb-4">
        
        <div class="modal-title text-center">
            {{ 'general.dateFilter.custom' | transloco }}
        </div>

        <mat-icon class="icon-size-10 text-primary-900 cursor-pointer" svgIcon="heroicons_outline:x" (click)="onCloseClick()"></mat-icon>
    </div>
    <div mat-dialog-content class="modal-content-container">
        <div class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-hidden">
            <mat-calendar (selectedChange)="_onSelectedChange($event)" [selected]="selectedDateRange">
            </mat-calendar>
        </div>
    </div>

    <div mat-dialog-actions class="modal-actions">
        <button color="primary" mat-raised-button type="button" (click)="selectRange()">
            {{ 'common.confirm' | transloco }}
        </button>
    </div>
</div>
