import { UriUtils } from 'app/shared/utils/uri.util';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';

export function queryTokenSetter() {
    const token = /\?token=(.+)&*/.exec(window.location.href)?.[1];
    if (token) {
        const authService = inject(AuthService);
        authService.accessToken = token;
        authService.refreshToken = token;
        localStorage.setItem('requestedEmailToken', token);
        UriUtils.deleteQueryParam('token');
    }
}
