import { Component } from '@angular/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { Moment } from 'moment';

@Component({
    selector: 'app-range-picker',
    templateUrl: './range-picker.component.html',
    styleUrl: './range-picker.component.scss',
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
    ],
})
export class RangePickerComponent {
    selectedDateRange: DateRange<Date>;

    constructor(private matDialogRef: MatDialogRef<RangePickerComponent>) {}

    _onSelectedChange(date: Date): void {
        if (
            this.selectedDateRange &&
            this.selectedDateRange.start &&
            date > this.selectedDateRange.start &&
            !this.selectedDateRange.end
        ) {
            this.selectedDateRange = new DateRange(
                this.selectedDateRange.start,
                date
            );
        } else {
            this.selectedDateRange = new DateRange(date, null);
        }
    }

    onCloseClick() {
        this.matDialogRef.close();
    }

    selectRange() {
        this.matDialogRef.close(this.selectedDateRange);
    }
}
