import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';

const calendarRoutes: Route[] = [
    {
        path: 'calendars',
        loadChildren: () => import('app/modules/calendar/calendar.module').then((m) => m.CalendarModule)
    },
];

const projectRouter: Route[] = [
    {
        path: 'provider',
        loadChildren: () => import('app/modules/provider/provider.module').then((m) => m.ProviderModule)
    },
    {
        path: 'customer',
        loadChildren: () => import('app/modules/customer/customer.module').then((m) => m.CustomerModule)
    },
    {
        path: 'project',
        loadChildren: () => import('app/modules/project/project.module').then((m) => m.ProjectModule)
    },
    {
        path: 'inventory',
        loadChildren: () => import('app/modules/inventory/inventory.module').then((m) => m.InventoryModule)
    },
    {
        path: 'budget',
        loadChildren: () => import('app/modules/budget/budget.module').then((m) => m.BudgetModule)
    },
    {
        path: 'bill',
        loadChildren: () => import('app/modules/bill/bill.module').then((m) => m.BillModule)
    },
    {
        path: 'category/list',
        loadComponent: () => import('app/modules/category/category.component').then((c) => c.CategoryComponent),
    },
];

const settingsRoutes: Route[] = [
    {
        path: 'defaults',
        loadChildren: () => import('app/modules/defaults/defaults.module').then((m) => m.DefaultsModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('app/modules/settings/settings.module').then((m) => m.SettingsModule)
    },
    {
        path: 'users',
        loadChildren: () => import('app/modules/user/user.module').then((m) => m.UserModule)
    },
    {
        path: 'security',
        loadChildren: () => import('app/modules/security/security.module').then((m) => m.SecurityModule)
    },
    {
        path: 'automations',
        loadChildren: () => import('app/modules/automations/automations.module').then((m) => m.AutomationsModule)
    },
    {
        path: 'integrations',
        loadChildren: () => import('app/modules/integrations/integrations.module').then((m) => m.IntegrationsModule)
    },
];

const errorRoutes: Route[] = [
    // 403 Forbidden
    {
        path: '403-forbidden',
        loadChildren: () => import('app/modules/error/forbidden/forbidden.module').then((m) => m.ForbiddenModule)
    },
    // 404 & Catch all
    {
        path: '404-not-found',
        loadChildren: () => import('app/modules/error/not-found/not-found.module').then((m) => m.NotFoundModule)
    },
];

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/event'
    {path: '', pathMatch : 'full', redirectTo: 'provider'},

    // Redirect signed-in user to the '/event'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'provider'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            // {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            // {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            // {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
            // {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            ...projectRouter,
            ...errorRoutes,
            ...settingsRoutes,
            ...calendarRoutes
        ]
    },

    { path: '**', redirectTo: '404-not-found' }
];
